
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { PropType } from 'vue';
  import { Project } from '@/modules/projects/types/entities';
  import {Actions, Getters} from '@/modules/projects/store/types/StoreTypes';
  import { namespace } from 'vuex-class';
  import { Comment } from '@/modules/entities/types/entities';
  import { Quote } from '@/modules/calendar/interfaces/project/quote';
  import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
  import { AppMessageType } from '@/core/components/app-message/entities';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { cloneDeep } from 'lodash';

  const projects = namespace('projects');

  @Component({
    components: {
      'quote-modal': () =>
        import(
          /* webpackChunkName: "quoteModal" */ '@/modules/projects/components/quote-modal/quote-modal.component.vue'
        ),
      'planner-modal': () =>
        import(
          /* webpackChunkName: "plannerModal" */ '@/modules/projects/components/planner-modal/planner-modal.component.vue'
        ),
    },
  })
  export default class ProjectOrdersListComponent extends Vue {
    @projects.Action(Actions.CREATE_PLANNER_PERIOD) createPlannerPeriod!: (payload: PlannerPeriod) => void;
    @projects.Action(Actions.EDIT_QUOTE) editOrder!: (payload: Quote) => void;
    @projects.Action(Actions.EDIT_PLANNER_PERIOD) editPlannerPeriod!: (payload: PlannerPeriod) => void;
    @projects.Action(Actions.REMOVE_QUOTE) removeOrder!: (payload: Quote) => void;
    @projects.Action(Actions.REMOVE_PLANNER_PERIOD) removePlannerPeriod!: (payload: PlannerPeriod) => void;
    @projects.Action(Actions.SAVE_PLANNER_PERIOD_COMMENT) savePlannerPeriodComment!: (payload: {
      plannerPeriodId: number;
      comment: Comment;
    }) => void;
    @projects.Action(Actions.SAVE_PLANNER_PERIOD) savePlannerPeriod!: (payload: {
      quoteId: number;
      plannerPeriod: PlannerPeriod;
    }) => void;
    @projects.Getter(Getters.GET_VALIDATION_ERRORS) validationErrors!: Object;

    @Prop({ type: Array as PropType<Quote[]>, default: () => [] }) orders!: Quote[];
    @Prop({ type: Object as PropType<Project>, required: true }) project!: Project;

    projectOrdersListState: {
      showPlannerModal: boolean;
      showQuoteModal: boolean;
    } = {
      showPlannerModal: false,
      showQuoteModal: false,
    };

    content: {
      orderCopy: Quote | null;
      plannerPeriod: PlannerPeriod | null;
      selectedPeriodId: number;
      quote: Quote | null;
    } = {
      orderCopy: null,
      plannerPeriod: null,
      selectedPeriodId: -1,
      quote: null,
    };

    resetContent(): void {
      this.content = {
        orderCopy: null,
        plannerPeriod: null,
        selectedPeriodId: -1,
        quote: null
      };
    }

    newPlannerPeriod(quote: Quote): void {
      const newPlannerPeriod = {
        id: -1,
        plannerStartDate: '',
        plannerEndDate: '',
        description: '',
        comments: [],
        subPeriods: [],
        quote: quote,
      };
      this.createPlannerPeriod(newPlannerPeriod);
    }

    onEditPlannerPeriod(quote: Quote, plannerPeriod: PlannerPeriod, value: string, prop: string): void {
      const plannerPeriodCopy: PlannerPeriod = cloneDeep(Object.assign({}, plannerPeriod, { quote: quote }));
      if (
        !this.content.plannerPeriod ||
        (this.content.plannerPeriod && plannerPeriodCopy.id !== this.content.plannerPeriod.id)
      ) {
        this.content.plannerPeriod = plannerPeriodCopy;
      }

      switch (prop) {
        case 'plannerStartDate':
          this.content.plannerPeriod.plannerStartDate = value;
          break;
        case 'plannerEndDate':
          this.content.plannerPeriod.plannerEndDate = value;
          break;
        default:
          break;
      }
      if (this.content.plannerPeriod.plannerStartDate && this.content.plannerPeriod.plannerEndDate) {
        if (this.content.plannerPeriod.id && this.content.plannerPeriod.id > 0)
          this.editPlannerPeriod(this.content.plannerPeriod);
        else this.createPlannerPeriod(this.content.plannerPeriod);
      }
    }

    onQuoteAssignmentToggle(quote: Quote, value: string): void {
      let quoteCopy: Quote = cloneDeep(quote)
      this.content.quote = quoteCopy
      switch (value) {
        case 'contractorType.none':
          quoteCopy.contractorTypeTempAssigned = false;
          quoteCopy.contractorTypePermAssigned = false;
          break;
        case 'contractorType.temp':
          quoteCopy.contractorTypeTempAssigned = true;
          quoteCopy.contractorTypePermAssigned = false;
          break;
        case 'contractorType.perm':
          quoteCopy.contractorTypePermAssigned = true;
          quoteCopy.contractorTypeTempAssigned = false;
          break;
        case 'contractor.none':
          quoteCopy.contractorPermAssigned = false;
          quoteCopy.contractorTempAssigned = false;
          break;
        case 'contractor.temp':
          quoteCopy.contractorTempAssigned = true;
          quoteCopy.contractorPermAssigned = false;
          break;
        case 'contractor.perm':
          quoteCopy.contractorPermAssigned = true;
          quoteCopy.contractorTempAssigned = false;
          break;
        default:
          break;
      }
      if (
        !quoteCopy.contractorTempAssigned &&
        !quoteCopy.contractorPermAssigned &&
        !quoteCopy.contractorTypeTempAssigned &&
        !quoteCopy.contractorTypePermAssigned
      ) {
        this.projectOrdersListState.showPlannerModal = false;
      }
      this.editOrder(quoteCopy);
    }

    onQuoteSupplierSelect(order: Quote): void {
      console.log('onQuoteSupplierSelect');
      this.resetContent();
      this.content.orderCopy = JSON.parse(JSON.stringify(order));
      this.projectOrdersListState.showQuoteModal = true;
    }

    onPlannerModalOpen(order: Quote, plannerPeriodId: number): void {
      this.resetContent();
      this.content.orderCopy = JSON.parse(JSON.stringify(order));
      this.content.selectedPeriodId = plannerPeriodId;
    }

    @Watch('content')
    onOrderCopyChange(newVal: { orderCopy: Quote | null; selectedPeriodId: number }): void {
      if (newVal.orderCopy) {
        if (newVal.selectedPeriodId > 0) {
          const foundPlannerPeriod =
            newVal.orderCopy.plannerPeriods &&
            newVal.orderCopy.plannerPeriods.find((x) => x.id === this.content.selectedPeriodId);
          if (foundPlannerPeriod) {
            this.projectOrdersListState.showPlannerModal = true;
          } else {
            appMessageStore.actions.set({
              message: this.$t('projects.details.orders.plannerPeriodNotFound', 'nl').toString(),
              ttl: 2500,
              dismissed: false,
              type: AppMessageType.WARNING,
            });
          }
        }
      }
    }

    @Watch('orders')
    onOrdersValueChange(newVal: Quote[]): void {
      if (this.content.orderCopy) {
        this.content.orderCopy = JSON.parse(
          JSON.stringify(newVal.find((x: Quote) => x.id === (this.content.orderCopy && this.content.orderCopy.id)))
        );
      }
    }

    get ordersCopy() {
      let ordersCopy = cloneDeep(this.orders);
      ordersCopy = ordersCopy.map((order) => {
        const orderCopy = cloneDeep(order);
        if (orderCopy.plannerPeriods && orderCopy.plannerPeriods.length === 0) {
          orderCopy.plannerPeriods = [
            { id: -orderCopy.id, plannerStartDate: '', plannerEndDate: '', description: '', comments: [], subPeriods: [] },
          ];
        }
        return orderCopy;
      });
      return ordersCopy;
    }
  }
